import { useStaticQuery, graphql } from 'gatsby'

const governancePageStaticQuery = graphql`
  query governancePageQuery {
    contentfulCustomPage(slug: { eq: "governance" }) {
      id
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      noResults {
        noResults
      }
      hero {
        ...HeroFragment
      }
    }
  }
`

const useGovernancePage = () => {
  const data = useStaticQuery(governancePageStaticQuery)

  return data.contentfulCustomPage
}

export default useGovernancePage
