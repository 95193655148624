import React, { useState } from 'react'
import { Modal } from '../../components/modal'

import { graphql, PageProps } from 'gatsby'
// import { board } from '../../components/board/board';
import Layout from '../../layouts/index'
import { SEO } from '../../components/seo'
import useGovernancePage from '../../utils/use-governance-page'
import { UserGroupIcon } from '@heroicons/react/24/outline'

export default function Board(props: PageProps) {
  const [showModal, setShowModal] = useState(false)
  const [executiveBio, setBio] = useState('')
  const [executiveName, setName] = useState('')
  const [executiveTitle, setTitle] = useState('')

  const board = props.data.board.edges
  const boardPage = props.data.boardPage
  const governancePage = useGovernancePage()
  const gridClasses = `space-y-12 grid grid-cols-1 sm:grid-cols-2 gap-x-6 space-y-0 lg:grid-cols-3 lg:gap-x-8 board-grid`

  const boardExists =
    'container py-4 mx-auto md:pt-0 lg:pb-20'

  const noBoard =
    'container py-4 mt-4 mx-auto md:pt-0 lg:pb-20'

  const toggleShowModal = (node) => {
    setShowModal(!showModal)
    setBio(node.biography.childMarkdownRemark.html)
    setName(node.name)

    if (node.boardTitle) {
      setTitle(node.boardTitle)
    } else {
      setTitle(node.title)
    }
  }

  const toggleCloseModal = () => {
    setShowModal(!showModal)
  }

  //   const tabs = [
  //     { name: 'board', href: '/governance/board', current: true },
  //     { name: 'Board of Directors', href: '/governance/board', current: false },
  //     { name: 'Governance', href: '/governance/index', current: false },
  //   ];
  return (
    <Layout
      location={props.location}
      heros={governancePage.hero}
      herosFallbackTitle={governancePage.title}
      herosFallbackSecondaryTitle="Board of Directors"
      tabs="governance"
    >
      <div className={board.length > 0 ? boardExists : noBoard}>
        {board.length > 0 ? (
          <div className="space-y-12">
            <Modal
              displayModal={showModal}
              toggleModal={toggleShowModal}
              confirmButtonText="Go Back"
              confirmCallBack={toggleCloseModal}
              largeModal
              board
              bodyText={
                <React.Fragment>
                    <h2
                      className="block executive-name"
                      style={{ marginTop: '.25em' }}
                    >
                      {executiveName}
                    </h2>
                    <h4 className="relative block text-xs tracking-wide uppercase font-univers -top-2">
                      {executiveTitle}
                    </h4>
                    <div
                      className="mx-auto executive-description"
                      dangerouslySetInnerHTML={{
                        __html: executiveBio
                      }}
                    />
                </React.Fragment>
              }
              headerText={<div>Biography</div>}
            />
            <ul className={gridClasses}>
              {board.map(({ node }) => {
                return (
                  <li key={node.name} className="p-2 sm:py-4 md:py-8 lg:px-2">
                    {/* <Link to={`/board/${node.slug}`}> */}
                    <div className="leading-6">
                      <h3
                        className="font-bold exec-bio"
                        onClick={() => toggleShowModal(node)}
                      >
                        {node.name}
                      </h3>
                      <span className="block mt-1 mb-2 text-sm text-gray-600 dark:text-gray-400 max-w-24">
                        {node.boardTitle ? (
                          node.boardTitle
                        ) : (
                          node.title
                        )}
                      </span>
                      {node.termDate && (
                        <span className="block text-xs text-gray-400 uppercase dark:text-gray-600">
                          <span className="font-medium tracking-wide">
                            Term:{' '}
                          </span>
                          <span>{node.termDate}</span>
                        </span>
                      )}
                    </div>
                    {/* </Link> */}
                  </li>
                )
              })}
            </ul>
          </div>
        ) : (
          <>
            {boardPage.noResults ? (
              <div className=" items-center flex justify-center flex-col py-20 rounded bg-gray-100 dark:bg-gray-900 mx-auto text-lg text-black dark:text-white">
                <UserGroupIcon className="w-12 h-12 text-gray-500" />
                <h6 className="flex justify-center mx-auto text-md text-gray-500">
                  {boardPage.noResults.noResults}
                </h6>
              </div>
            ) : null}
          </>
        )}
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    boardPage: contentfulCustomPage(slug: { eq: "board" }) {
      noResults {
        noResults
      }
    }
    board: allContentfulPeople(
      filter: { type: { in: "Board Member" } }
      sort: { order: [DESC, ASC], fields: [sticky, weight] }
    ) {
      edges {
        node {
          name
          title
          boardTitle
          slug
          termDate
          picture {
            gatsbyImageData(width: 180, resizingBehavior: SCALE)
          }
          biography {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
